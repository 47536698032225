import React, { useEffect, useState } from "react";
import axios from "axios";
import "./QuickLook.scss";
import { Card, Layout } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import MetaTagContents from "../../MetaTagContents";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";
import { PRODUCT_LIST, getBaseURL } from "../../env";

const QuickLook = () => {
  const { title, name, content } = MetaTagContents.QUICKLOOK;
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const productIds = [
    31499, 37706, 13702, 37378, 31467, 28260, 28956, 30877, 30892, 30476,  28948, 28798, 17379, 30531, 44122,
    28787, 37715, 29307, 38072, 37710, 37518, 37707, 33345, 37763, 27941, 28942, 37376,     
  ];

  const getProducts = () => {
    let payload = {
      required_fields: ["id", "name", "thumbnail", "category", "platform"],
    };
    payload["order_by"] = "id desc";
    payload["filter_string"] = `(product_ids__in=[${productIds.join(",")}])`;

    axios.post(PRODUCT_LIST, payload).then((response) => {
      const sortedProducts = response.data.sort(
        (a, b) => productIds.indexOf(a.id) - productIds.indexOf(b.id)
      );
  
      setProductData(sortedProducts);
      setLoading(false);
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getThumbnail = (id, platform) => {
    return getBaseURL(platform) + 'product_assets/perspective_renders/'+ id + '_HeadOn_512.jpg';
  };

  const ProductCard = ({ product }) => {
    return (
      <Card className="quicklook-card" bordered={false}>
        <a
          href={`https://app.all3d.ai/product_public_viewer/product_3d_model/${product.id}`}
          className="suggested-product"
        >
          <div className="suggested-product apply-Avenir">
            <div
              className="store-card"
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                key={product.id}
                className="product-image"
                src={getThumbnail(product.id, product.platform)}
                style={{ objectFit: "contain" }}
                alt="Product Image"
              />
            </div>
          </div>
        </a>
      </Card>
    );
  };

  const WebVersion = () => {
    return (
      <div className="quicklook-container">
        <div className="quicklook-inner-container">
          <div className="quicklook-heading-content">
            <h1 className="font lora w-400 f-32 ws-6 black-2b">Quick Look</h1>
            <p>
              3D models created by the ALL3D.ai platform for immersive,
              real-world experiences. <a href="/contact-us">Contact us</a> today to bring your products to
              life in brilliant 3D!
            </p>
          </div>
          <div className="quicklook-product-container">
            {loading && (
              <div className="quicklook-loading-container">
                <LoadingOutlined />
              </div>
            )}
            <div className="quicklook-product-list">
              {productData.map((product, index) => {
                return <ProductCard key={index} product={product} />;
              })}
            </div>
          </div>
          <div className="quicklook-heading-content mt-80">
            <h1 className="font lora w-400 f-32 ws-6 black-2b">
              Examples on the web
            </h1>
            <p>
              See these models created by ALL3D.ai in action on the web. Click
              on the links below to view the models in your browser.
            </p>
          </div>
          <div className="examples-on-web-container">
            <div className="examples-on-web-card">
              <img className="cubePhoneImgs" src="/quicklook/cubeExample.svg" />
              <div className="cube-info-text">
                <h1 className="font lora w-400 f-28 ws-6 black-2b">
                  The Stock Cube
                </h1>
                <p>
                  Three sleek, high-resolution LED displays deliver more data at
                  a glance than any other ticker out there..
                </p>
                <a
                  href="https://thestockcube.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Visit the website</p>
                </a>
              </div>
            </div>
            <div className="sqa-card">
              <div className="sqa-info-text">
                <h1 className="font lora w-400 f-28 ws-6 black-2b">SQAI</h1>
                <p>
                  The SQAI Power Suit uses Electro Muscle Stimulation (EMS) to
                  deliver a deep, comprehensive workout in just 20 minutes.
                </p>
                <a
                  href="https://sqai.co/suit/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Visit the website</p>
                </a>
              </div>
              <img
                className="sqaPhoneImg"
                src="/quicklook/sqaSuitExample.svg"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MobileVersion = () => {
    return <div></div>;
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href="https://all3d.ai/quicklook" />
        <meta name={name} content={content} />
      </Helmet>

      <NavBar />

      <Layout>
        <WebVersion />
        <MobileVersion />
      </Layout>
      <Footer />
    </React.Fragment>
  );
};

export default QuickLook;
